import React from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import Link from 'components/Link'

const ButtonWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const StyledButton = styled(Button)({
  marginTop: '8px',
  padding: '17px 32px',
  fontFamily: 'dinPro, Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 600,
})

const Overline = styled('h3')({
  display: 'block',
  marginTop: "4rem",
  color: ({ theme }) => theme.colors.base.black,
})

export default function StoryblokButton({ data }) {
  const { label, overline, color, link } = data
  return (
    <ButtonWrapper>
      {overline && <Overline>{overline}</Overline>}
      <Link link={link}>
        <StyledButton color={color}>{label}</StyledButton>
      </Link>
    </ButtonWrapper>
  )
}
